import React, { Component } from 'react'
import Head from 'next/head'
import dynamic from 'next/dynamic'
import Button from '../components/Button'
import Page from '../util/Page'

import { SITE_TITLE_PREFIX } from '../config'
const Animation = dynamic(() => import('../components/Animation'), { ssr: false })

export default class NotFound extends Component {
  render() {
    return (
      <Page className="NotFound">
        <Head>
          <title>{`${SITE_TITLE_PREFIX} 404 - page not found`}</title>
          <link rel="canonical" href={'https://kubesail.com'} />
        </Head>
        <h2
          style={{
            fontSize: '3rem',
            fontFamily: 'var(--monospaceFont)',
            marginBottom: '2rem',
            marginTop: '2rem',
          }}
        >
          404 Not Found
        </h2>
        <div
          style={{
            maxWidth: 300,
            lineHeight: '1.5rem',
          }}
        >
          We tried so hard, and we looked so far, but in the end, this page doesn't exist
        </div>
        <div className="animation" style={{ position: 'relative', top: '-50px' }}>
          <Animation speed={0.85} animation="four-oh-four" />
        </div>
        <div style={{ position: 'relative', top: '-50px' }}>
          <Button href="/homepage" as="/homepage">
            home
          </Button>
        </div>
      </Page>
    )
  }
}
